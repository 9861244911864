import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import toast from "react-hot-toast";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

// components
import EmailHeader from "./EmailHeader";
import ApprovalCount from "../approval/ApprovalCount";

// hooks
import useApprove from "../../../hooks/useApprove";

// other
import api from "../../../services/api/api";
import ButtonOutline from "../../ui/ButtonOutline";
import ButtonBlack from "../../ui/ButtonBlack";
import DownArrow from "../post/DownArrow";
import TextWithParagraphs from "../../../utils/TextWithParagraphs";
import Message from "../email/Message";

const EmailScreen: React.FC = () => {
  const { isScrolledToBottom } = useApprove();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingSkip, setIsUpdatingSkip] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<any>();
  const params = useParams();
  const location = useLocation();
  const fullPath = window.location.origin + location.pathname;
  const navigate = useNavigate();

  const fetchUrl = `/pa/sendgrid/info/${params.token}`;

  const isApproved = data?.content?.state === "complete";

  console.log(data?.template?.body_font_color);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(fullPath)
      .then(() => {
        toast.success("Copied to the clipboard!");
      })
      .catch((error) => {
        toast.error("Something went wrong... Couldn't perform this operation");
      });
  };

  const approveHandler = async () => {
    setIsUpdating(true);
    let response;
    try {
      response = await api.get(`/pa/sendgrid/save/${params.token}`  + location.search);
      if (response.data === "success") {
        toast.success("Email has been approved!");

        if(data.content_approval){
          setData(null);
          navigate(data.content_approval.next);
        } else {
          const resp = await api.get(`${fetchUrl}`);
          setData(resp.data);
        }
        
        setIsUpdating(false);
      } else {
        throw new Error();
      }
    } catch {
      toast.error("Something went wrong. Try again!");
      setIsUpdating(false);
    }
  };

  const skipHandler = async () => {
    setIsUpdatingSkip(true);
    try {

      const method = isApproved ? '/approve' : '/skip';

      await api.get(`/pa/content-approval/`  + data.content_approval.id + method);
        toast.success("Item has been skipped!");
        setIsUpdatingSkip(false);
        setData(null)
        window.scrollTo(0, 0);
        navigate(data.content_approval.next);
    } catch {
      toast.error("Something went wrong. Try again!");
      setIsUpdatingSkip(false);
    }
  };

  useEffect(() => {
    if (data) {
      return;
    }
    const fetchData = async () => {
      let resp;
      try {
        resp = await api.get(fetchUrl + location.search);
        if (!resp.data.content) {
          throw new Error();
        }
        setData(resp.data);
      } catch (err) {
        setIsError(true);
      }
    };
    fetchData();
  }, [data, fetchUrl, params.token]);

  if (isError) {
    return (
      <Container>
        <LoaderContainer>
          It looks like you have a wrong link...
        </LoaderContainer>
      </Container>
    );
  }

  if (!data) {
    return (
      <Container>
        <LoaderContainer>Loading...</LoaderContainer>
      </Container>
    );
  }

  return (
    <Container>
      {data.content_approval && <ApprovalCount pagination={data.content_approval}></ApprovalCount>}
      <ContentContainer $bgColor={data.template.body_background_color}>
        <SubjectLine>
          <div>{data.content.subject}</div>
        </SubjectLine>
        <EmailHeader
          color={data.template.header_background_color}
          url={data.template.logo}
        />
        <MainImg src={data.content.body_image} />
        <Spacer />
        <ContentTitle
          $fontSize={data.template.title_font_size}
          $fontColor={data.template.title_font_color}
          $font={data.template.title_font_style}
        >
          {data.content.title}
        </ContentTitle>
        <Spacer />
        <Body
          $fontSize={data.template.body_font_size}
          $fontColor={data.template.body_font_color}
          $font={data.template.body_font_style}
        >
          {TextWithParagraphs(data.content.body_text)}
        </Body>
        <ButtonLink href={`https://${data.content.button_url}`} target="new">
          <Button
            $bgColor={data.template.button_background_color}
            $color={data.template.button_font_color}
            $fontSize={data.template.button_font_size}
            $fontStyle={data.template.button_font_style}
          >
            {data.content.button_text}
          </Button>
        </ButtonLink>
        {data?.content?.sections?.length
          ? data.content.sections.map((section: any, index: number) => {
              if (section.type === "title") {
                return (
                  <div key={`section${index}`}>
                    <ContentTitle
                      $fontSize={data.template.title_font_size}
                      $fontColor={data.template.title_font_color}
                      $font={data.template.title_font_style}
                    >
                      {section.value}
                    </ContentTitle>
                    <Spacer />
                  </div>
                );
              }
              if (section.type === "image") {
                return (
                  <div key={`section${index}`}>
                    <MainImg src={section.value} key={`section${index}`} />
                    <Spacer />
                  </div>
                );
              }
              if (section.type === "text") {
                return (
                  <div key={`section${index}`}>
                    <Body
                      $fontSize={data.template.body_font_size}
                      $fontColor={data.template.body_font_color}
                      $font={data.template.body_font_style}
                      key={`section${index}`}
                    >
                      {TextWithParagraphs(section.value)}
                    </Body>
                    <Spacer />
                  </div>
                );
              }
              if (section.type === "button") {
                return (
                  <div key={`section${index}`}>
                    <ButtonLink href={`${section.other_value}`} target="new">
                      <Button
                        $bgColor={data.template.button_background_color}
                        $color={data.template.button_font_color}
                        $fontSize={data.template.button_font_size}
                        $fontStyle={data.template.button_font_style}
                      >
                        {section.value}
                      </Button>
                    </ButtonLink>
                    <Spacer />
                  </div>
                );
              }
            })
          : null}

      

        <FooterContainer
          $bgColor={data.template.footer_background_color}
          $color={data.template.footer_font_color}
          $fontSize={data.template.body_font_size}
          $font={data.template.body_font_style}
        >
          <FooterParagraph>{data.content.client.name}</FooterParagraph>
          <FooterParagraph>{data.template.address}</FooterParagraph>
          <FooterParagraph>
            Unsubscribe - Unsubscribe Preferences
          </FooterParagraph>
        </FooterContainer>
      </ContentContainer>

      {data.content.notify_message && (
        <MessageContainer>
          {/* <Message status={true} title={data.content.notify_message.title} subtitle={data.content.notify_message.subtitle} /> */}
          <Message status={data.content.notify_message.status} title={data.content.notify_message.title} subtitle={data.content.notify_message.subtitle} />
        </MessageContainer>
      )}
      <BottomContainer>
        {!isScrolledToBottom && <DownArrow />}
        {isScrolledToBottom && (
          <>
            <ButtonOutline label="Copy link" clickHandler={copyToClipboard} />
            {data.content_approval && <ButtonOutline label="Skip" clickHandler={skipHandler} isLoading={isUpdatingSkip}/>}
            {isApproved ? (
              <ButtonBlack
                label="Approved!"
                bgColor="#9B7CEB"
                onClick={() => {}}
              />
            ) : (
              <ButtonBlack
                label="Approve"
                onClick={approveHandler}
                isLoading={isUpdating}
              />
            )}
          </>
        )}
      </BottomContainer>
    </Container>
  );
};

export default EmailScreen;

const Container = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 1rem 1rem 24rem 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ContentContainerProps = {
  $bgColor: string;
};

const ContentContainer = styled.div<ContentContainerProps>`
  background-color: ${({ $bgColor }) => $bgColor};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  gap: 0;
  align-items: center;
  border-radius: 2rem;
  border: 1px solid #ddd;
`;

const MainImg = styled.img`
  width: 100%;
`;

type ContentTitleType = {
  $fontSize: string;
  $fontColor: string;
  $font: string;
};

const ContentTitle = styled.div<ContentTitleType>`
  text-align: center;
  font-size: ${({ $fontSize }) => `${$fontSize}px`};
  color: ${({ $fontColor }) => $fontColor};
  font-family: ${({ $font }) => $font};
  margin: 0 0.5rem;
`;

type BodyType = {
  $fontSize: string;
  $fontColor: string;
  $font: string;
};

const Body = styled.div<BodyType>`
  padding: 0 4rem;
  text-align: center;
  color: ${({ $fontColor }) => $fontColor};
  font-family: ${({ $font }) => $font};
  font-size: ${({ $fontSize }) => `${$fontSize}px`};
`;

type ButtonProps = {
  $bgColor: string;
  $color: string;
  $fontSize: string;
  $fontStyle: string;
};

const Button = styled.div<ButtonProps>`
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $color }) => $color};
  font-family: ${({ $fontStyle }) => $fontStyle};
  font-size: ${({ $fontSize }) => `${$fontSize}px`};
  cursor: pointer;
  border-radius: 1.4rem;
  margin: 2rem 0 4rem 0;
  text-align: center;
  padding: 1rem 2rem;
  width: auto;
`;

const ButtonLink = styled.a`
  text-decoration: none;
`;

type FooterContainerType = {
  $bgColor: string;
  $color: string;
  $fontSize: string;
  $font: string;
};

const FooterContainer = styled.div<FooterContainerType>`
  width: 100%;
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $color }) => $color};
  text-align: center;
  padding: 2rem 4rem;
  font-size: ${({ $fontSize }) => $fontSize};
  font-family: Space Grotesk, sans-serif;
  // border-left: solid 1px #bfbfbf;
  // border-right: solid 1px #bfbfbf;
  // border-bottom: solid 1px #bfbfbf;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
`;

const FooterParagraph = styled.p`
  margin-bottom: 1rem;
`;

const Spacer = styled.div`
  min-height: 3rem;
`;

const BottomContainer = styled.div`
  position: fixed;
  bottom: 1.2rem;
  gap: 1rem;
  width: 100%;
  padding: 0 1rem 2rem 1rem;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const MessageContainer = styled.div`
  height: 24.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubjectLine = styled.div`
  padding: 20px;
  width: 100%;
  font-size: 18px;
  text-align: left;
  border-top-left-radius: 1.7rem;
  border-top-right-radius: 1.7rem;
  background: #fff;
`;